/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Survey } from '../../types/GeoReportTypes';

interface Props {
	setEmail: (email: string) => void
	surveys: Survey[];
	handleFileInput: (file: File, type: number) => void;
	selectedFile: File | null;
	selectedImage: (selectedImage: string) => void
}

const FileSubmit = (props: Props) => {
	const { surveys, setEmail, handleFileInput, selectedFile, selectedImage } = props;


	return (
		<div className='m-3 flex flex-wrap ml-5'>
			<div className='mb-1 space-y-3'>
				<h1 className='text-base sm:text-xl font-bold text-black text-left'>
					Submeter estudo geotécnico
				</h1>
				<p className='text-sm sm:text-base mt-1 text-left'>
					{' '}
					Foram submetido(s) {surveys.length} ponto(s) de sondagem geotécnica.
				</p>
			</div>
			<div className='mb-1'>
				<div>
					<p className='text-sm sm:text-base text-left'>
						Iremos analisar os inputs e validar os resultados, submeta o respectivo
						relatório e clique em &quot;Enviar&quot; para finalizar o processo.
					</p>
					<br></br>
				</div>
				<div className='flex items-center justify-center w-full'>
					<label
						htmlFor='dropzone-file'
						className='flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'
					>
						<div className='flex flex-col items-center justify-center pt-5 pb-6'>
							<svg
								aria-hidden='true'
								className='w-10 h-10 mb-3 text-gray-400'
								fill='none'
								stroke='currentColor'
								viewBox='0 0 24 24'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeWidth='2'
									d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12'
								></path>
							</svg>
							<p className='mb-2 text-sm text-gray-500 dark:text-gray-400'>
								{selectedFile ? (
									<span className='font-semibold'>{selectedFile.name}</span>
								) : (
									<span className='font-semibold'>Seleciona ou arrasta</span>
								)}
							</p>
							<p className='text-xs text-gray-500 dark:text-gray-400'>PDF</p>
						</div>
						<input
							id='dropzone-file'
							type='file'
							className='hidden'
							onChange={(e: any) => handleFileInput(e, 1)}
						/>
					</label>
				</div>
				{/* <div className='justify-center mb-5 items-center flex space-x-2'>
					<label className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Escolher ficheiro</label>
					<input className='block w-full text-sm text-gray-900 border border-blue-helica rounded-lg cursor-pointer bg-blue-helica dark:text-gray-400 focus:outline-none dark:blue-helica dark:blue-helica dark:placeholder-gray-400' type='file' name='file' onChange={handleFileInput} />
					<button onClick={handleUpload} className="bg-blue-helica w-48 h-10 sm:max-w-32 rounded-md text-xs sm:text-xs text-white p-1">Upload</button>
				</div> */}
			</div>
			<div className='w-full'>
				<p className='text-xs sm:text-sm text-left'>
					{' '}
					Caso seja necessário esclarecer a informação colocada, por favor insira o seu email.
				</p>
				<div className='mt-1'>
					<label
						htmlFor='email'
						className='bg-white block mb-1 text-sm sm:text-base text-blue-helica'
					>
						E-mail *
					</label>
					<input
						type='text'
						id='email'
						name='email'
						className='bg-white border mb-2 border-neutral-300 text-sm sm:text-sm rounded-lg focus:outline-none focus:border-neutral-300 focus:ring-transparent block w-full h-8 p-1'
						onChange={e => setEmail(e.target.value)}
                        required
					/>
				</div>
			</div>
            {/*<p className='text-xs sm:text-sm text-left py-2'>
				Insira o seu logotipo se desejar:
			</p>*/}
			<div className='w-full' >
				{/*<label
					htmlFor='dropzone-image'
					className='flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'
				>
					<div className='flex flex-col items-center justify-center pt-5 pb-6'>
						<svg
							aria-hidden='true'
							className='w-10 h-10 mb-3 text-gray-400'
							fill='none'
							stroke='currentColor'
							viewBox='0 0 24 24'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12'
							></path>
						</svg>
						<p className='mb-2 text-sm text-gray-500 dark:text-gray-400'>
							{selectedImage ? (
								<span className='font-semibold'>{selectedImage.name}</span>
							) : (
								<span className='font-semibold'>Seleciona ou arrasta</span>
							)}
						</p>
						<p className='text-xs text-gray-500 dark:text-gray-400'>JPEG / PNG</p>
					</div>
					<input
						id='dropzone-image'
						type='file'
						className='hidden'
						onChange={(e: any) => handleFileInput(e, 999999)}
					/>
				</label>*/}
				<div className='mt-1'>
					<label
						htmlFor='logo'
						className='bg-white block mb-1 text-sm sm:text-base text-blue-helica'
					>
						Logotipo
					</label>
					<input
						type='text'
						id='logo'
						name='logo'
						className='bg-white border mb-2 border-neutral-300 text-sm sm:text-sm rounded-lg focus:outline-none focus:border-neutral-300 focus:ring-transparent block w-full h-8 p-1'
						onChange={e => selectedImage(e.target.value)}
                        placeholder='URL do logotipo'
                        required
					/>
				</div>
			</div>
		</div>
	);
};

export default FileSubmit;