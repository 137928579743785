import React from 'react';
//import './App.css';
import Footer from './components/Footer/Footer';
import PrrBanner from './components/Footer/PrrBanner';
import Index from './components/Map';
import Notification from './components/Notification/Notification';
import { SurveyProvider } from './context/SurveyContext';

function App() {
	return (
		<>
			<div className='flex flex-col justify-between'>
				<SurveyProvider>
					<Index />
				</SurveyProvider>
				<Footer />
                <PrrBanner />
				<Notification />
			</div>
		</>
	);
}

export default App;