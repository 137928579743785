import React from 'react';
import SocialsList from './SocialsList';
import prrBanner from '../../img/BARRA_2024.jpg';

const Footer = () => {
return (
    <div className="w-screen">
        <img className="md:w-1/3 w-screen mx-auto" src={prrBanner}></img>
    </div>
	);
};

export default Footer;