export const depthList = [
	{ id:1 , label: 2},
	{ id:2 , label: 4},
	{ id:3 , label: 6},
	{ id:4 , label: 8},
	{ id:5 , label: 10},
	{ id:6 , label: 12},
	{ id:7 , label: 14},
	{ id:8 , label: 16},
	{ id:9 , label: 18},
	{ id:10 , label: 20},
	{ id:11 , label: 22},
	{ id:12 , label: 24},
	{ id:13 , label: 26},
	{ id:14 , label: 28},
	{ id:15 , label: 30}
	
];
