import React, { createContext, useContext, useState } from 'react';
import { userMarkerDispatch, userMarkerState } from '../types/ContextTypes';

const initialState: userMarkerState = {
	isPlaced: false,
	lat: 0,
	lng: 0,
	proxLat: 0,
	proxLng: 0,
	instance: undefined,
};

const UserMarkerContext = createContext<userMarkerState | undefined>(undefined);
const UserMarkerDispatchContext = createContext<userMarkerDispatch | undefined>(undefined);

export const useUserMarkerState = () => {
	const state = useContext(UserMarkerContext);
	if (!state) {
		throw new Error('UserMarkerContext must be used within a provider.');
	}
	return state;
};

export const useUserMarkerDispatch = () => {
	const dispatch = useContext(UserMarkerDispatchContext);
	if (!dispatch) {
		throw new Error('UserMakerDispatch must be used within a provider.');
	}
	return dispatch;
};

export const UserMarkerProvider = ({ children }: { children: React.ReactNode }) => {
	const [state, setState] = useState(initialState);
	const dispatch = {
		setIsPlaced: (isPlaced: boolean) => setState((prevState) => ({ ...prevState, isPlaced })),
		setLat: (lat: number) => setState((prevState) => ({ ...prevState, lat })),
		setLng: (lng: number) => setState((prevState) => ({ ...prevState, lng })),
		setProxLat: (proxLat: number) => setState((prevState) => ({ ...prevState, proxLat })),
		setProxLng: (proxLng: number) => setState((prevState) => ({ ...prevState, proxLng })),
		setInstance: (instance: google.maps.Marker) => setState((prevState) => ({ ...prevState, instance })),
	};

	return (
		<UserMarkerContext.Provider value={state}>
			<UserMarkerDispatchContext.Provider value={dispatch}>
				{children}
			</UserMarkerDispatchContext.Provider>
		</UserMarkerContext.Provider>
	);
};
