/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { GoogleMapsProvider } from '@ubilabs/google-maps-react-hooks';
import React, { useState, useEffect, useCallback} from 'react';
import { useGeoReportState } from '../../context/GeoReportContext';
import { useMapContext, useMapDispatch } from '../../context/MapContext';
import { options } from './MapElements';
import TestMap from './TestMap';

const Index = () => {
	const [mapContainer, setMapContainer] = useState<HTMLDivElement | null>(null);

    const clearCacheData = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
        //alert('Complete Cache Cleared');
    };

	const mapDispatch = useMapDispatch();
	const mapState = useMapContext();
	const reportState = useGeoReportState();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onLoad = useCallback((map: any) => {
        clearCacheData();
        //setTimeout(() => {console.log('timeout');}, 100000);
        google.maps.event.trigger(map, 'resize');
		mapDispatch.setMap(map);
        //google.maps.event.trigger(map, 'resize');
	}, [reportState]);
	return (
        <>
		<div className='flex flex-col w-full h-full'>
			<GoogleMapsProvider
				mapOptions={options(mapState)}
				googleMapsAPIKey={process.env.REACT_APP_API_KEY as string}
				mapContainer={mapContainer}
				version='beta'
				onLoadMap={onLoad}
				libraries={['places']}
			>
				<TestMap />
				<div ref={(node) => setMapContainer(node)} style={{ height: '100vh' }} />
			</GoogleMapsProvider>
		</div> 


    </>
	);
};


//import React, { useState, useEffect, useCallback} from 'react';
//import {APIProvider, Map, Marker} from '@vis.gl/react-google-maps';
//import TestMap from './TestMap';


//function Index() {
//  const position = {lat: 39.557191, lng: -7.8536599};

//  	const mapDispatch = useMapDispatch();
//	const mapState = useMapContext();
//	const reportState = useGeoReportState();

//    const onLoad = useCallback((map: any) => {
//		mapDispatch.setMap(map);
//	}, [reportState]);


//  return (
//    <APIProvider apiKey={'AIzaSyA6hFb93eu-yTY1iqw6rCB0yuI1G-ucj3o'}>
//        <div className='h-screen'>
//            <Map center={position} zoom={9} mapId='fcb9b5430aa9d7bf'>
//                <TestMap />
//            </Map>
//      </div>
//    </APIProvider>
//  );
//}

export default Index;
