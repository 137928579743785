import React, { createContext, useContext, useState } from 'react';
import { notificationDispatch, notificationState } from '../types/ContextTypes';

const initialState: notificationState = {
	message: '',
	type: 0,
	isVisible: false,
};

const NotificationContext = createContext<notificationState | undefined>(undefined);
const NotificationDispatchContext = createContext<notificationDispatch | undefined>(undefined);

export const useNotificationState = () => {
	const state = useContext(NotificationContext);
	if (!state) {
		throw new Error('GlobalStateContext must be used within a provider.');
	}
	return state;
};

export const useNotificationDispatch = () => {
	const dispatch = useContext(NotificationDispatchContext);
	if (!dispatch) {
		throw new Error('GlobalDispatchContext must be used within a provider.');
	}
	return dispatch;
};

export const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
	const [state, setState] = useState(initialState);
	const dispatch = {
		setMessage: (message: string) => setState((prevState) => ({ ...prevState, message })),
		setType: (type: number) => setState((prevState) => ({ ...prevState, type })),
		setIsVisible: (isVisible: boolean) =>
			setState((prevState) => ({ ...prevState, isVisible })),
	};

	return (
		<NotificationContext.Provider value={state}>
			<NotificationDispatchContext.Provider value={dispatch}>
				{children}
			</NotificationDispatchContext.Provider>
		</NotificationContext.Provider>
	);
};
