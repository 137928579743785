export const optionList = [
	{ id:1, label: 'Selecione Solo' },
    { id:2, label: 'Aluvião' },
	{ id:3, label: 'Areia argilosa' },
	{ id:4, label: 'Areia bem-graduada' },
	{ id:5, label: 'Areia lodosa' },
	{ id:6, label: 'Areia mal-graduada' },
	{ id:7, label: 'Areia siltosa' },
	{ id:8, label: 'Argila arenosa' },
	{ id:9, label: 'Argila com plasticidade alta' },
	{ id:10, label: 'Argila com plasticidade baixa' },
	{ id:11, label: 'Argila dura' },
	{ id:12, label: 'Argila inorgânica' },
	{ id:13, label: 'Argila lodosa' },
	{ id:14, label: 'Argila orgânica' },
	{ id:15, label: 'Argila rígida' },
	{ id:16, label: 'Argila siltosa' },
	{ id:17, label: 'Argilito' },
	{ id:18, label: 'Aterro' },
	{ id:19, label: 'Biocalcarenito' },
	{ id:20, label: 'Calcarenito' },
    { id:21, label: 'Calcário' },
	{ id:22, label: 'Cascalho argiloso' },
	{ id:23, label: 'Cascalho bem-graduado' },
	{ id:24, label: 'Cascalho mal-graduado' },
	{ id:25, label: 'Cascalho siltoso' },
	{ id:26, label: 'Marga calcária' },
    { id:27, label: 'Rochoso (grão médio a grosseiro)' },
	{ id:28, label: 'Seixo rolado' },
	{ id:29, label: 'Silte arenoso' },
	{ id:30, label: 'Silte inorgânico' },
	{ id:31, label: 'Silte orgânico' },
	{ id:32, label: 'Turfa e outros solos altamente orgânicos' },
	{ id:33, label: 'Outro' },
    { id:34, label: 'Não identificado' },

	
	
];
