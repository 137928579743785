import React, { useState } from 'react';
import { SurveyData } from '../../types/SurveyTypes';
import { optionList } from '../../data/soilTypes';
import { depthList } from '../../data/Depths';
import { IconContext } from 'react-icons';
import { AiFillEdit, AiFillDelete, AiFillSave } from 'react-icons/ai';
import { notificationHandler } from '../../utils/ContextHandlers';
import { useNotificationDispatch } from '../../context/NotificationContext';

import Button from './Button';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


type Props = {
	tableData: SurveyData[];
	setTableData: React.Dispatch<React.SetStateAction<SurveyData[]>>;
}

function Table({ tableData, setTableData }: Props) {

	const notificationDispatch = useNotificationDispatch();

	const [curLength, setCurLength] = useState<number>(0);
	// const depthList = [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30];

	const [editingId, setEditingId] = useState<number | null>(null);
	const [newData, setNewData] = useState<{ soilType: string; height: number; depth: number; spt1: number; spt2: number }>({
		soilType: optionList[0].label,
		height: 0,
		depth: depthList[0].label,
		spt1: 0,
		spt2: 0,
	});

	const handleAdd = (event: { preventDefault: () => void; }) => {
		event.preventDefault();
		if (editingId !== null) {
			notificationHandler(2, 'Guarde os valores da linha anterior', notificationDispatch);
			return;
		} else {
			setCurLength(curLength + 1);
			const id = Date.now();
			setTableData([...tableData, { id: id, ...newData }]);
			setEditingId(id);
		}
	};

	const handleEdit = (id: number, event: { preventDefault: () => void; }) => {
		event.preventDefault();
		setEditingId(id);
		const item = tableData.find((item) => item.id === id);
		if (item) {
			setNewData({ soilType: item.soilType, height: item.height, depth: item.depth, spt1: item.spt1, spt2: item.spt2 });
		}

	};

	const handleSave = (id: number, event: { preventDefault: () => void; }) => {
		event.preventDefault();
		const updatedData = { ...newData };

		if (updatedData.soilType === 'Selecione Solo') {
			notificationHandler(2, 'Introduza um tipo de solo válido', notificationDispatch);
			return;
		} else if (updatedData.spt1 < 0 || updatedData.spt2 > 60) {
			notificationHandler(2, 'Introduza um valor de SPT (1ª fase) válido', notificationDispatch);
			return;
		} else if (updatedData.spt2 < 0 || updatedData.spt2 > 60) {
			notificationHandler(2, 'Introduza um valor de SPT (2ª fase) válido', notificationDispatch);
			return;
		}

		const rowData = tableData.map((item) => (item.id === id ? { ...item, ...newData } : item));
		setTableData(rowData);
		setEditingId(null);
		if (curLength < 1) {
			setNewData({ soilType: optionList[0].label, height: rowData[0].height, depth: depthList[0].label, spt1: 0, spt2: 0 });
		} else {
			setNewData({ soilType: optionList[0].label, height: rowData[0].height, depth: depthList[0].label, spt1: 0, spt2: 0 });
		}
	};

	const handleDelete = (id: number, event: { preventDefault: () => void; }) => {
		event.preventDefault();
		setCurLength(curLength - 1);
		setTableData(tableData.filter((item) => item.id !== id));
		if (curLength < 1) {
			setNewData({ soilType: optionList[0].label, height: 0, depth: depthList[0].label, spt1: 0, spt2: 0 });
		} else {
			setNewData({ soilType: optionList[0].label, height: 0, depth: depthList[0].label, spt1: 0, spt2: 0 });
		}
	};

	return (
		<table className='text-center w-full max-h-10'>
			<thead className='text-xs sm:text-sm'>
				<tr className='text-blue-helica p-1 h-10'>
					<th scope='col' className='border-b font-normal'>Tipo de solo
                    <Popup
                        trigger={open => (<button className="button w-4 h-4" type='button'><Button /></button>)}
                        position="left center"
                        closeOnDocumentClick
                    >
                        <span className="text-xs">Escolha o tipo de solo presente no intervalo de profundidades respectivo. Caso não tenha a certeza selecione a opção `Outro`. Caso não tenha essa informação disponível utilize a carta geológica ou marque `Outro`.</span>
                    </Popup></th>
					<th scope='col' className='border-b font-normal'>Cota (m)
                    <Popup
                        trigger={open => (<button className="button w-4 h-4" type='button'><Button /></button>)}
                        position="left center"
                        closeOnDocumentClick
                    >
                        <span className="text-xs">Inicie com a cota à superfície. Subtraia a profundidade marcada nos subsquentes registos.</span>
                    </Popup></th>
					<th scope='col' className='border-b font-normal'>Profundidade (m)
                    <Popup
                        trigger={open => (<button className="button w-4 h-4" type='button'><Button /></button>)}
                        position="left center"
                        closeOnDocumentClick
                    >
                        <span className="text-xs">Escolha a profundidade da sondagem.</span>
                    </Popup></th>
					<th scope='col' className='border-b font-normal'>SPT<br></br>(1ª fase)
                    <Popup
                        trigger={open => (<button className="button w-4 h-4" type='button'><Button /></button>)}
                        position="left center"
                        closeOnDocumentClick
                    >
                        <span className="text-xs">Insira o valor SPT à profundidade selecionada. Caso não tenha este valor deixe nulo.</span>
                    </Popup></th>
					<th scope='col' className='border-b font-normal'>SPT<br></br>(2ª fase)
                    <Popup
                        trigger={open => (<button className="button w-4 h-4" type='button'><Button /></button>)}
                        position="left center"
                        closeOnDocumentClick
                    >
                        <span className="text-xs">Insira o valor SPT à profundidade selecionada. Caso tenha valores DPSH preencha aqui os valores SPT equivalentes.</span>
                    </Popup></th>
					<th scope='col' className='border-b font-normal'>Ações</th>
				</tr>
			</thead>
			<tbody>
				{tableData.map((item) => (
					<tr key={item.id} className='flex-col overflow-x-auto -space-y-px h-10'>
						<td className='border-y text-center text-xs sm:text-sm'>
							{editingId === item.id ? (
								<select className='w-full border-transparent focus:outline-none focus:border-transparent focus:ring-transparent text-xs sm:text-sm'
									value={newData.soilType} onChange={(e) => setNewData({ ...newData, soilType: e.target.value })}>
									{optionList.map((opt) =>
										<option key={opt.id}>{opt.label}</option>
									)};
								</select>
							) : (
								item.soilType
							)}
						</td>
						<td className='border-y text-center text-xs sm:text-sm'>
							{editingId === item.id ? (
								<input
									type="number"
									value={newData.height}
									className='border-none w-full border-transparent focus:outline-none focus:border-transparent focus:ring-transparent text-center text-xs sm:text-sm'
									onChange={(e) =>
										setNewData({ ...newData, height: Number(e.target.value) })
									}
								/>
							) : (
								item.height
							)}
							{/* {tableData.indexOf(item) === 0 ? (
								<>
									{editingId === item.id ? (
										<input
											type="number"
											value={newData.height}
											className='border-none w-full border-transparent focus:outline-none focus:border-transparent focus:ring-transparent text-center text-xs sm:text-sm'
											onChange={(e) =>
												setNewData({ ...newData, height: Number(e.target.value) })
											}
										/>
									) : (
										item.height
									)}
								</>
							) : (
								item.height
							)} */}
						</td>
						<td className='border-y text-center text-xs sm:text-sm'>
							{editingId === item.id ? (
								<select className='w-full border-transparent focus:outline-none focus:border-transparent focus:ring-transparent text-xs sm:text-sm'
									value={newData.depth} onChange={(e) => setNewData({ ...newData, depth: Number(e.target.value) })}>
									{depthList.map((opt) =>
										<option key={opt.id}>{opt.label}</option>
									)};
								</select>
							) : (
								item.depth
							)}
							{/* {editingId === item.id ? (
								<input
									type="number"
									value={newData.depth}
									className='border-none w-full border-transparent focus:outline-none focus:border-transparent focus:ring-transparent text-center text-xs sm:text-sm'
									onChange={(e) =>
										setNewData({ ...newData, depth: Number(e.target.value) })
									}
								/>
							) : (
								<item.depth>
							)} */}
							{/* {item.depth} */}
						</td>
						<td className='border-y text-center text-xs sm:text-sm'>
							{editingId === item.id ? (
								<input
									type="number"
									value={newData.spt1}
									className='border-none w-full border-transparent focus:outline-none focus:border-transparent focus:ring-transparent text-center text-xs sm:text-sm'
									onChange={(e) =>
										setNewData({ ...newData, spt1: Number(e.target.value) })
									}
								/>
							) : (
								item.spt1
							)}
						</td>
						<td className='border-y text-center text-xs sm:text-sm'>
							{editingId === item.id ? (
								<input
									type="number"
									value={newData.spt2}
									className='border-none w-full border-transparent focus:outline-none focus:border-transparent focus:ring-transparent text-center text-xs sm:text-sm'
									onChange={(e) =>
										setNewData({ ...newData, spt2: Number(e.target.value) })
									}
								/>
							) : (
								item.spt2
							)}
						</td>
						<td className='border-y text-center text-xs sm:text-sm'>
							{editingId === item.id ? (
								<button onClick={(e) => handleSave(item.id, e)}>
									<IconContext.Provider
										value={{
											color: '#060E9F',
											size: '1em',
											style: { verticalAlign: 'middle' },
										}}
									>
										<AiFillSave />
									</IconContext.Provider>
								</button>
							) : (
								<>
									<button onClick={(e) => handleEdit(item.id, e)}>
										<IconContext.Provider
											value={{
												color: '#060E9F',
												size: '1em',
												style: { verticalAlign: 'middle' },
											}}
										>
											<AiFillEdit />
										</IconContext.Provider>
									</button>
									<button onClick={(e) => handleDelete(item.id, e)}>
										<IconContext.Provider
											value={{
												color: '#060E9F',
												size: '1em',
												style: { verticalAlign: 'middle' },
											}}
										>
											<AiFillDelete />
										</IconContext.Provider>
									</button>
								</>
							)}
						</td>
					</tr>
				))}
			</tbody>
			<>
				{/* {curLength === 15 ? (
					<tfoot className='border-t'>
						<tr className="h-10">
						</tr>
					</tfoot>
				) : (<tfoot className='border-t'>
					<tr className="h-10 flex">
						<td>
							<button className="bg-transparent m-1 text-xs sm:text-sm text-blue-helica" onClick={handleAdd}>+ Adicionar</button>
						</td>
					</tr>
				</tfoot>)} */}
				{<tfoot className='border-t'>
					<tr className="h-10 flex">
						<td>
							<button className="bg-transparent m-1 text-xs sm:text-sm text-blue-helica" onClick={handleAdd}>+ Adicionar</button>
						</td>
					</tr>
				</tfoot>}
			</>
		</table>
	);
}

export default Table;
