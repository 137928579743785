import { mapDispatch, notificationDispatch, SurveyDispatch, userMarkerDispatch } from '../types/ContextTypes';

export const notificationHandler = (type: number, message: string, dispatch: notificationDispatch) => {
	dispatch.setType(type);
	dispatch.setMessage(message);
	dispatch.setIsVisible(true);
};

export const userMarkerHandler = (lat: number, lng: number, proxLat: number, proxLng: number, dispatch: userMarkerDispatch) => {
	dispatch.setLat(lat);
	dispatch.setLng(lng);
	dispatch.setProxLat(proxLat);
	dispatch.setProxLng(proxLng);
	dispatch.setIsPlaced(true);
};

export const mapHandler = (lat: number, lng: number, zoom: number, dispatch: mapDispatch) => {
	dispatch.setLat(lat);
	dispatch.setLng(lng);
	dispatch.setZoom(zoom);
	dispatch.setIsVisible(false);
};


export const surveyLengthHandler = (length: number, dispatch: SurveyDispatch) => {
	dispatch.setLength(length);
};


