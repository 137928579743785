import * as React from 'react';
import Instruct from './Instructions';
import UserMarker from '../Map/UserMarker';
import { geocoder, reverseGeocoder } from '../../utils/Util';
import { useNotificationDispatch } from '../../context/NotificationContext';
import { useGeoReportState } from '../../context/GeoReportContext';
import { useUserMarkerDispatch } from '../../context/UserMarkerContext';
import { useMapContext, useMapDispatch } from '../../context/MapContext';
import { GeoReport } from '../../types/GeoReportTypes';
import setas from '../../img/setas.png';
import setasWhite from '../../img/setas-white.png';
import logo from '../../img/dados-do-solo-logo.png';
import PopupGfg from './Popup';




const Navbar = (props: { setPointDataArray: React.Dispatch<React.SetStateAction<GeoReport | null>>, setDistanceBetween: React.Dispatch<React.SetStateAction<number | null>>}) => {
	const notificationDispatch = useNotificationDispatch();
	const geoReportState = useGeoReportState();
	const userMarkerDispatch = useUserMarkerDispatch();
	const mapDispatch = useMapDispatch();
	const mapContext = useMapContext();

    const [showPopup, setPopup] = React.useState(false);

    React.useEffect(() => {
        // Use a setTimeout to set isTrue to true after 3 seconds
        setTimeout(() => {
          setPopup(true);
        }, 7000); // 7000 milliseconds = 7 seconds
    });

	const [showModal, setShowModal] = React.useState(false);

	const handleSubmit = async (lat?: number, lng?: number, value?: string) => {
		if (lat && lng) {
			const response = await reverseGeocoder(
				lat,
				lng,
				notificationDispatch,
				geoReportState,
				userMarkerDispatch,
				mapDispatch,
				mapContext,
			);

			const report = response.closestReport;
			const distanceBetween = response.distance;

			props.setDistanceBetween(null);
			props.setDistanceBetween(distanceBetween);
			props.setPointDataArray(null);
			props.setPointDataArray(report);
		}
		if (value) {
			const response = await geocoder(value, userMarkerDispatch, mapDispatch, notificationDispatch, geoReportState, mapContext);

			const report = response.closestReport;
			const distanceBetween = response.distanceBetween;

			props.setDistanceBetween(null);
			props.setDistanceBetween(distanceBetween);
			props.setPointDataArray(null);
			props.setPointDataArray(report);
		}
	};

	const handleOpenInstruct = () => {
		setShowModal(!showModal);
	};

	return (
		<>
			<div className='grid md:grid-cols-3 gap-4 sm:grid-rows-3 place-content-center h-auto md:h-32 pt-8 pb-4 md:pt-10 pattern-dots pattern-gray-600 pattern-bg-blue-helica pattern-opacity-100 pattern-size-2'>
				{/* LOGO */}
				<div className='md:m-auto md:pr-2'>
					<a href="/"><img src={logo} alt="dados do solo by Helica" className='w-1/2 sm:w-48 mx-auto' /></a>
				</div>
				{/* SEARCH BAR */}
				<div className='m-auto pt-4 pb-4 md:pt-0 md:pb-0'>
					{/* <h1 className='text-xs sm:text-xs font-light text-blue-helica'>Procurar por:</h1> */}
					<UserMarker handleSubmit={handleSubmit} />
				</div>

				<div className='grid grid-cols-2 place-items-center md:place-items-end md:pr-12'>
					<button type="button" className='flex items-center p-2 space-x-2 sm:space-x-4 md:w-48 w-42 md:h-12 h-10 border-blue-helica rounded-sm border bg-white' onClick={handleOpenInstruct}>
						<span className="font-semibold font-sans sm:ml-4 text-xs sm:text-xs text-blue-helica">COMO FUNCIONA</span>
						<img src={setas} className='h-2'></img>
					</button>

					{/* HELICA WEBPAGE */}
					<button type="button" className='flex items-center p-2 space-x-2 sm:space-x-4 md:w-48 w-42 md:h-12 h-10 border-white rounded-sm border bg-transparent'>
						<a href="https://helica.pt" target="_blank" rel="noreferrer" className="font-semibold font-sans sm:ml-4 text-xs sm:text-xs text-white">CONHEÇA A HELICA</a>
						<img src={setasWhite} className='h-2'></img>
					</button>
				</div>
			</div>

			{showModal ?
				<>
					<div className='bg-white z-50 h-auto sm:h-2/3 overflow-x-hidden'>
						<Instruct />
					</div>
				</>
				: null}

            { showPopup ?
				<>
					<div className='bg-white z-50 h-auto sm:h-2/3 overflow-x-hidden'>
						<PopupGfg />
					</div>
				</>
				: null}

		</>
	);
};

export default Navbar;