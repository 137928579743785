import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import setas from '../../img/setas-white.png';



export default function PopupGfg(){
    
    const [showPopup, setPopup] = React.useState(true);
    
    const [emailForm, setEmailForm] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    //Result if message was sent or not
    const [result, setResult] = useState('');

    //Status of while message is being sent
    const [status, setStatus] = useState('Submeter');

    function resetEmailForm() {
        setName('');
        setPhone('');
        setEmail('');
        setMessage('');
    }
    
    const handleSubmit = async (e) => {
        setResult('');
        e.preventDefault();
        setStatus('A enviar...');

        const { name, phone, email, message } = e.target.elements;

        const details = {
            name: name.value,
            email: email.value,
            phone: phone.value,
            message: message.value,
        };

        try {
            const response = await fetch(process.env.REACT_APP_BACKEND + '/api/sendmessage', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(details),
            });
            setStatus('Submeter');
            const result = await response.json();

            if (response.ok) {
                setResult('Mensagem Enviada!');
                resetEmailForm();
            } else if (result.status === 'fail') {
                alert('Uh oh! Mensagem não foi enviada.');
            }
        } catch (error) {
            console.error(error);
            setStatus('Submeter');
            setResult('Uh oh! Problemas no envio da mensagem.');
        }
    };


  return(
    <>
    {showPopup ?
    <>
        <div className='container h-auto sm:w-1/3 p-8 rounded-sm bg-white absolute sm:start-1/3 top-1/4'>
            <p className='text-center text-xl font-bold text-blue-helica align-middle'>NECESSITA DE UM ESTUDO GEOTÉCNICO?</p>
            <p className='text-center text-md mt-4 font-regular text-black align-middle'>Descubra a base sólida do seu projeto! Entre em contato agora para obter um estudo geotécnico.</p>
            <div className='mt-8 mx-auto max-w-screen-md justify-center'>
                <form id='contact-form' onSubmit={handleSubmit} method='POST' className="space-y-4">
                    <div>
                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Nome</label>
                        <input type="text" id="name" onChange={(e) => setName(e.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" required />
                    </div>
                    <div>
                        <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Contacto</label>
                        <input type="tel" id="phone" onChange={(e) => setPhone(e.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" required />
                    </div>
                    <div>
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">E-mail</label>
                        <input type="email" id="email" onChange={(e) => setEmail(e.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" required />
                    </div>
                    <div>
                        <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Mensagem</label>
                        <textarea id="message" onChange={(e) => setMessage(e.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" required />
                    </div>
                    <button type="submit" className='py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 bg-blue-helica'>{status}</button>
                    <p className='text-gray-300'>{result}</p>
                </form>
            </div>
            <div className='mt-8 flex justify-center'>
                <button type='button' className='flex justify-center text-xs underline text-light' onClick={() => setPopup(false)}>fechar</button>
            </div>
        </div>
        </>
        : null}
    </>
);
};
