import React, { useRef, useEffect, useState } from 'react';
import { useGoogleMap } from '@ubilabs/google-maps-react-hooks';
import { useUserMarkerDispatch, useUserMarkerState } from '../../context/UserMarkerContext';
import LatLong from './LatLong';
import Places from '../Navbar/Places';

interface Props {
    handleSubmit: (lat?: number, lng?: number, value?: string) => void;
}

const UserMarker = (props: Props) => {
	const userMarkerState = useUserMarkerState();
	const userMarkerDispatch = useUserMarkerDispatch();
	const map = useGoogleMap();
	const markerRef = useRef<google.maps.Marker | undefined>();
	const { handleSubmit } = props;
	const [value, setValue] = React.useState('');
	const [inputBoxCoord, setInputBoxCoord] = useState(true);
	const [inputBoxAdrr, setInputBoxAdrr] = useState(false);

	const optionList = [
		{ id: 1, label: 'Coordenadas', value: 'coord' },
		{ id: 2, label: 'Morada', value: 'morada' },
	];

	const handleChangeSelection = (e: { target: { value: React.SetStateAction<string> } }) => {
		setValue(e.target.value);

		if (e.target.value === 'morada') {
			setInputBoxAdrr(true);
			setInputBoxCoord(false);
		}
		if (e.target.value === 'coord') {
			setInputBoxCoord(true);
			setInputBoxAdrr(false);
		}
	};
	useEffect(() => {
		if (!map || markerRef.current || !userMarkerState.isPlaced) return;
		markerRef.current = new google.maps.Marker({ map });
		userMarkerDispatch.setInstance(markerRef.current);
	}, [userMarkerState.isPlaced, map]);

	useEffect(() => {
		if (!markerRef.current || !userMarkerState.isPlaced) return;
		if (!userMarkerState.isPlaced) return;
		if (isNaN(userMarkerState.lat) || isNaN(userMarkerState.lng)) return;
		markerRef.current.setPosition({ lat: userMarkerState.lat, lng: userMarkerState.lng });
		map?.panTo({ lat: userMarkerState.lat, lng: userMarkerState.lng });
		map?.setZoom(14);
	}, [userMarkerState.lat, userMarkerState.lng, userMarkerState.isPlaced, map]);

	return (
		<>
			<div className='flex space-x-2 font-sans font-medium'>
				<div>
					{inputBoxCoord ?
						<LatLong handleSubmit={handleSubmit}/>
						: null}
					{inputBoxAdrr ?
						<Places handleSubmit={handleSubmit}/>
						: null}
				</div>
				<div>
					<select className='border-transparent w-22 sm:w-40 h-10 focus:outline-none focus:border-transparent focus:ring-transparent font-light text-xs sm:text-xs text-blue-helica' value={value} onChange={handleChangeSelection}>
						{optionList.map((opt) =>
							<option key={opt.id} value={opt.value}>{opt.label}</option>
						)};
					</select>
				</div>
			</div>
		</>
	);
	
};

export default UserMarker;