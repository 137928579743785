/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { GeoReport } from '../../types/GeoReportTypes';
import { findAverage, findMode } from '../../utils/Util';
import setas from '../../img/setas.png';
import Popup from '../Navbar/Popup';

interface tableData {
	cota: number,
	profundidade: number,
	spt1: number,
	spt2: number,
    spt1ND: string
}

interface Props {
	//imgData: any[],
	pointDataArray: GeoReport | null,
	distance: number | null,
	handleClosePointData: () => void,
	map: google.maps.Map | undefined,
}

const PointData = (props: Props) => {

	const { pointDataArray, distance, handleClosePointData, map } = props;
    //imgData,

	//const imageName = pointDataArray?.imgName;
	//const [imageId, setImageId] = useState();

	//const handleImageData = () => {
	//	imgData.forEach((img) => {
	//		if (img.name === imageName) {
	//			setImageId(img.id);
	//		}
	//	});
	//};

    //useEffect(() => {

    //}, []);

    //console.log(pointDataArray?.imgName);

	//useEffect(() => { handleImageData(); }, []);

	if (!pointDataArray) {
		return null;
	};

	const dataArray: tableData[] = [];
	const depthMap: Map<number, { spt1Values: number[], spt2Values: number[], spt1ValuesND: string }> = new Map();

	const removeZoneLayer = (map: google.maps.Map | undefined, placeId: string) => {
		if (!map) {
			return null;
		}
		else {
			if (!map.getMapCapabilities().isDataDrivenStylingAvailable) return;
			const featureLayer = map.getFeatureLayer(google.maps.FeatureType.ADMINISTRATIVE_AREA_LEVEL_1);
			const featureStyleOptions: google.maps.FeatureStyleOptions = {
				strokeColor: '#FA8334',
				strokeOpacity: 0,
				strokeWeight: 0,
				fillColor: '#FA8334',
				fillOpacity: 0,
			};
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			featureLayer.style = (options: { feature: { placeId: string } }) => {
				if (options.feature.placeId === placeId) {
					return featureStyleOptions;
				}
			};
		}
	};

	pointDataArray.surveys.forEach(survey => {
		survey.table.forEach((rowData) => {
			const depth = rowData.depth;

			const spt1 = Number(rowData.spt1);
			const spt2 = Number(rowData.spt2);

			let depthData = depthMap.get(depth);
			if (!depthData) {
				depthData = { spt1Values: [], spt2Values: [], spt1ValuesND: '' };
				depthMap.set(depth, depthData);
			}

            if (spt1 == 1000) {
                depthData.spt1ValuesND = 'n.d.';
            } else {
                depthData.spt1Values.push(spt1);
            }

			depthData.spt2Values.push(spt2);
		});
	});

	depthMap.forEach((depthData, depth) => {
		const spt1M = findAverage(depthData.spt1Values);
		const spt2M = findAverage(depthData.spt2Values);

        if (spt1M == 1000 ) {
            dataArray.push({
                cota: depth - 2,
                profundidade: depth,
                spt1: 0,
                spt2: Math.round(spt2M),
                spt1ND: depthData.spt1ValuesND
            });    
        } else {
            dataArray.push({
                cota: depth - 2,
                profundidade: depth,
                spt1: Math.round(spt1M),
                spt2: Math.round(spt2M),
                spt1ND: ''
            });
    
        }

		//dataArray.push({
		//	cota: depth - 2,
		//	profundidade: depth,
		//	spt1: Math.round(spt1M),
		//	spt2: Math.round(spt2M),
		//});
	});

	const firstMode = findMode([pointDataArray.surveys[0].table[0].soilType]);
	const lastMode = findMode([pointDataArray.surveys[pointDataArray.surveys.length - 1].table[pointDataArray.surveys[pointDataArray.surveys.length - 1].table.length - 1].soilType]);
    
    const [showPopup, setShowPopup] = React.useState(true);
    const [emailForm, setEmailForm] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    //Result if message was sent or not
    const [result, setResult] = useState('');

    //Status of while message is being sent
    const [status, setStatus] = useState('Submeter');

    function resetEmailForm() {
        setName('');
        setPhone('');
        setEmail('');
        setMessage('');
    }
    
    const handleSubmit = async (e) => {
        setResult('');
        e.preventDefault();
        setStatus('A enviar...');

        const { name, phone, email, message } = e.target.elements;

        const details = {
            name: name.value,
            email: email.value,
            phone: phone.value,
            message: message.value,
        };

        try {
            const response = await fetch(process.env.REACT_APP_BACKEND + '/api/sendmessage', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(details),
            });
            setStatus('Submeter');
            const result = await response.json();

            if (result === 'All good') {
                setResult('Mensagem Enviada!');
                resetEmailForm();
                setTimeout(() => {setShowPopup(!showPopup);}, 2000);
            } else if (result.status === 'fail') {
                alert('Uh oh! Mensagem não foi enviada.');
            }
        } catch (error) {
            console.error(error);
            setStatus('Submeter');
            setResult('Uh oh! Problemas no envio da mensagem.');
        }
    };

    const handleOpenPopup = () => {
		setShowPopup(!showPopup);
	};

	return (
        <>
			<div className='px-3 sm:px-5 sm:w-auto'>
				<div className='py-3 sm:py-5 flex justify-between'>
					{distance ? (
						<div>
							<p className='text-sm sm:text-base'>{Math.round(distance * 0.001)} km(s) aprox. até ao relatório mais próximo</p>
							<p className='text-sm sm:text-base'>{pointDataArray.surveys.length} ponto(s) de sondagem nesta localização</p>
						</div>
					) : (
						<p className='text-sm sm:text-base'>{pointDataArray.surveys.length} ponto(s) de sondagem nesta localização</p>
					)}
					<button type='button' className='' onClick={() => { handleClosePointData(); removeZoneLayer(map, pointDataArray.placeId); }}>X</button>
				</div>
				<div className='py-1 sm:py-3'>
					<p className='font-bold text-sm sm:text-base'>Informação geotécnica</p>
				</div>
				<div className='space-y-4'>
					<div className='pt-1 sm:pt-3'>
						<p className='text-sm sm:text-base'>A sua localização apresenta as seguintes características a nível geotécnico:</p>
					</div>
					<table className='text-center w-full max-h-10'>
						<thead className='text-xs sm:text-sm'>
							<tr className='text-blue-helica p-1 h-10'>
								<th scope='col' className='border-b font-normal'>
									Profundidade (m)
								</th>
								<th scope='col' className='border-b font-normal'>
									SPT<br></br>(1ª fase / valor médio)
								</th>
								<th scope='col' className='border-b font-normal'>
									SPT<br></br>(2ª fase / valor médio)
								</th>
							</tr>
						</thead>
						<tbody>
							{dataArray.map((data, index) => (
								<Table key={index} data={data} />
							))}
						</tbody>
					</table>
					<div>
						<p className='text-sm sm:text-base'>Tipo de solo mais frequente (1<sup>as</sup> camadas)</p>
						<p className='font-bold text-sm sm:text-base'>{firstMode}</p>
					</div>
					<div>
						<p className='text-sm sm:text-base'>Tipo de solo mais frequente (em profundidade)</p>
						<p className='font-bold text-sm sm:text-base'>{lastMode}</p>
					</div>
				</div>
                {/*{ imageId ? (*/}
                    <div className='py-5 flex flex-wrap items-center'>
                        <p className='mr-2'>Relatório fornecido por: </p>
                        <img src={pointDataArray?.selectedImage} className='w-1/3 h-1/3' />
                    </div>
                {/*):
                    <div className='py-5 flex flex-wrap items-center'></div>
                }*/}
				<div className='flex h-auto rounded-xl bg-blue-helica p-6 pattern-dots pattern-gray-600 pattern-bg-blue-helica pattern-opacity-100 pattern-size-2 xl:pattern-size-4 mb-4'>
                    <p className='text-center font-semibold text-white align-middle'>NECESSITA DE UM ESTUDO GEOTÉCNICO?</p>
                    <button type="button" onClick={handleOpenPopup} className='flex xl:mx-8 items-center space-x-4 sm:w-40 h-12 border-blue-helica rounded-sm border bg-white'>
                        <a className="font-semibold font-sans ml-4 ext-sm sm:text-xs text-blue-helica">CLIQUE AQUI</a>
                        <img src={setas} className='h-2'></img>
                    </button>
                </div>
                { showPopup ?
                <>
                <p className='text-center text-md mt-4 font-regular text-black align-middle'>Entre em contato agora para obter um estudo geotécnico.</p>
                <div className='mt-8 mx-auto max-w-screen-md justify-center'>
                <form id='contact-form' onSubmit={handleSubmit} method='POST' className="space-y-4">
                    <div>
                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Nome</label>
                        <input type="text" id="name" onChange={(e) => setName(e.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" required />
                    </div>
                    <div>
                        <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Contacto</label>
                        <input type="tel" id="phone" onChange={(e) => setPhone(e.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" required />
                    </div>
                    <div>
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">E-mail</label>
                        <input type="email" id="email" onChange={(e) => setEmail(e.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" required />
                    </div>
                    <div>
                        <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Mensagem</label>
                        <textarea id="message" onChange={(e) => setMessage(e.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" required />
                    </div>
                    <button type="submit" className='py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 bg-blue-helica'>{status}</button>
                    <p className='text-gray-300'>{result}</p>
                </form>
            </div>
            </>
            : null}
			</div>
		</>
	);
};

const Table = (props: { data: tableData }) => {

	const { data } = props;

	return (
		<tr className='flex-col overflow-x-auto -space-y-px'>
			<td className='border-y m-none w-max text-center text-xs sm:text-sm'>{data.profundidade}</td>
			<td className='border-y m-none w-max text-center text-xs sm:text-sm'>{data.spt1}</td>
			<td className='border-y m-none w-max text-center text-xs sm:text-sm'>{data.spt2}</td>
		</tr>
	);
};

export default PointData;