import React, { createContext, useContext, useState } from 'react';
import { mapDispatch, mapState } from '../types/ContextTypes';

const initialState: mapState = {
	lat: 39.69478,
	lng: -8.13027,
	zoom: 7.5,
	opacity: 1,
	color: '#ffffff',
	map: undefined,
	isVisible: true,
};

const MapContext = createContext<mapState | undefined>(undefined);
const MapDispatchContext = createContext<mapDispatch | undefined>(undefined);

export const useMapContext = () => {
	const state = useContext(MapContext);
	if (!state) {
		throw new Error('MapContext must be used within a provider.');
	}
	return state;
};

export const useMapDispatch = () => {
	const dispatch = useContext(MapDispatchContext);
	if (!dispatch) {
		throw new Error('GlobalDispatchContext must be used within a provider.');
	}
	return dispatch;
};

export const MapProvider = ({ children }: { children: React.ReactNode }) => {
	const [state, setState] = useState(initialState);
	const dispatch = {
		setLat: (lat: number) => setState((prevState) => ({ ...prevState, lat })),
		setLng: (lng: number) => setState((prevState) => ({ ...prevState, lng })),
		setZoom: (zoom: number) => setState((prevState) => ({ ...prevState, zoom })),
		setOpacity: (opacity: number) => setState((prevState) => ({ ...prevState, opacity })),
		setColor: (color: string) => setState((prevState) => ({ ...prevState, color })),
		setMap: (map: google.maps.Map) => setState((prevState) => ({ ...prevState, map })),
		setIsVisible: (isVisible: boolean) => setState((prevState) => ({ ...prevState, isVisible })),
	};

	return (
		<MapContext.Provider value={state}>
			<MapDispatchContext.Provider value={dispatch}>
				{children}
			</MapDispatchContext.Provider>
		</MapContext.Provider>
	);
};
