import React, { useState } from 'react';
import FormTable from './FormTable';
import { SurveyData } from '../../types/SurveyTypes';
import { useGoogleMap } from '@ubilabs/google-maps-react-hooks';
import { mapClickGeoCoder } from '../../utils/Util';
import { notificationHandler } from '../../utils/ContextHandlers';
import { useNotificationDispatch } from '../../context/NotificationContext';

import Button from './Button';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

interface Props {
	handleOpenFileSubmit: () => void;
	handleAddSurvey: (event: { preventDefault: () => void; }, lat: number, lng: number, nFreatico: boolean, inFreatico: number, nSPT: boolean, table: SurveyData[]) => void;
	handleClearSurveys: (event: { preventDefault: () => void }) => void;
	surveyNumber: number;
}

const Form = (props: Props) => {
	const map = useGoogleMap();
	const notificationDispatch = useNotificationDispatch();

	const { surveyNumber, handleOpenFileSubmit, handleAddSurvey, handleClearSurveys } = props;

	const [lat, setLat] = useState('');
	const [lng, setLng] = useState('');
	const [checkLevel, setCheckLevel] = useState<boolean>(false);
    const [checkSPT, setCheckSPT] = useState<boolean>(false);
	const [levelWater, setLevelWater] = useState('');
	const [tableData, setTableData] = useState<SurveyData[]>([]);

	const handleMapLatLngSelection = (event: { preventDefault: () => void; }) => {
		event.preventDefault();

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const listener = async (mapsMouseEvent: any) => {
			const check = await mapClickGeoCoder(mapsMouseEvent.latLng.lat(), mapsMouseEvent.latLng.lng(), notificationDispatch);
			if (check) {
				setLat(mapsMouseEvent.latLng.lat);
				setLng(mapsMouseEvent.latLng.lng);
			}

			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			google.maps.event.clearListeners(map!, 'click');
		};

		map?.addListener('click', listener);
	};

	const handleResetForm = () => {
		const value = '';
		setLat(value);
		setLng(value);
		setLevelWater(value);
		if (checkLevel === true) {
			setCheckLevel(!checkLevel);
		}
        if (checkSPT === true) {
			setCheckSPT(!checkSPT);
		}
		setTableData([]);
	};

	const handleResetForm2 = () => {
		const value = '';
		// setLat(value);
		// setLng(value);
		setLevelWater(value);
		if (checkLevel === true) {
			setCheckLevel(!checkLevel);
		}
        if (checkSPT === true) {
			setCheckSPT(!checkSPT);
		}
		setTableData([]);
	};

	return (
		<>
			<div className="px-3 py-3 space-y-4 content-center h-auto w-screen sm:w-auto" id='surveyForm'>
				<div className='mb-1.5 sm:mb-3 space-y-4'>
					<p className='text-base sm:text-xl font-bold text-black text-left'>Submeter estudo geotécnico</p>
					<p className='text-sm sm:text-base font-normal text-black text-left'>Sondagem {surveyNumber}</p>
				</div>
				<div className='mb-1 sm:mb-2 flex justify-evenly space-x-2 items-end'>
					<div className='w-full'>
						<label htmlFor="inLat" className="bg-white mb-1 text-xs sm:text-base text-blue-helica">Coordenadas N/S</label>
						<input type="number" id="inLat" autoComplete='off' className="bg-white border border-neutral-300 text-sm sm:text-base rounded-lg focus:outline-none focus:border-neutral-300 focus:ring-transparent h-10 w-full p-1" value={lat} onChange={(e) => setLat(e.target.value)} />
					</div>
					<div className='w-full'>
						<label htmlFor="inLong" className="bg-white mb-1 text-xs sm:text-base text-blue-helica">Coordenadas E/W</label>
						<input type="number" id="inLong" autoComplete='off' className="bg-white border border-neutral-300 text-sm sm:text-base rounded-lg focus:outline-none focus:border-neutral-300 focus:ring-transparent h-10 w-full p-1" value={lng} onChange={(e) => setLng(e.target.value)} />
					</div>
					<div className='w-full'>
						<button
							type='button'
							className='bg-blue-helica w-full h-10 rounded-md text-xs text-white p-1'
							onClick={handleMapLatLngSelection}
						>
							Selecionar no mapa
						</button>
					</div>
                    <Popup
                        trigger={open => (<button className="button w-14 h-14" type='button'><Button /></button>)}
                        position="left center"
                        closeOnDocumentClick
                    >
                        <span className="text-xs">Após clicar no botão, clique na localização pretendida no mapa para recolher as coordenadas.</span>
                    </Popup>
				</div>
				<div className="flex items-center mb-1 sm:mb-2">
					<input id="default-checkbox" checked={checkLevel} type="checkbox" className="w-4 h-4 accent-blue-helica bg-white border border-neutral-300 rounded focus:outline-none focus:border-neutral-300 focus:ring-transparent" onChange={() => setCheckLevel(!checkLevel)} />
					<label htmlFor="default-checkbox" className="ml-2 text-sm sm:text-sm text-blue-helica">Nível freático encontrado</label>
                    <Popup
                        trigger={open => (<button className="button w-6 h-6 ml-2" type='button'><Button /></button>)}
                        position="left center"
                        closeOnDocumentClick
                    >
                        <span className="text-xs">Selecione caso a sondagem atinja o nível freático. Escreva a profundidade no campo abaixo.</span>
                    </Popup>
				</div>
				<div className='mb-1 sm:mb-2'>
					<label htmlFor="inFreatico" className="bg-white block mb-1 text-sm sm:text-sm text-blue-helica">Profundidade do nível freático (m)</label>
					<input type="number" id="inFreatico" autoComplete='off' disabled={!checkLevel} className="disabled:bg-neutral-200 bg-white border border-neutral-300 text-sm sm:text-sm rounded-lg focus:outline-none focus:border-neutral-300 focus:ring-transparent block w-full h-8 p-1" value={levelWater} onChange={(e) => setLevelWater(e.target.value)} />
				</div>
                <div className="flex items-center mb-1 sm:mb-2">
					<input id="default-checkbox" checked={checkSPT} type="checkbox" className="w-4 h-4 accent-blue-helica bg-white border border-neutral-300 rounded focus:outline-none focus:border-neutral-300 focus:ring-transparent" onChange={() => setCheckSPT(!checkSPT)} />
					<label htmlFor="default-checkbox" className="ml-2 text-sm sm:text-sm text-blue-helica">Não tenho valores de SPT (1ª fase)</label>
                    <Popup
                        trigger={open => (<button className="button w-6 h-6 ml-2" type='button'><Button /></button>)}
                        position="left center"
                        closeOnDocumentClick
                    >
                        <span className="text-xs">Caso use valores de SPT de conversão de outro método de ensaio selecione para que a coluna SPT (1ª fase) fique como `não definido` e preencha os seus valores em SPT (2ª fase).</span>
                    </Popup>
				</div>
				<div className='mb-2 sm:mb-4'>
					<p className="bg-white block text-sm sm:text-sm text-blue-helica mb-1 space-y-5">Solos encontrados</p>
					<div className="flex flex-col min-w-full overflow-y-auto border rounded-md">
						<FormTable tableData={tableData} setTableData={setTableData} />
					</div>
				</div>
				<div className="flex justify-evenly space-x-2">
					<button
						onClick={(event) => {
							handleResetForm();
							handleClearSurveys(event);
						}}
						type='reset'
						className='bg-blue-helica w-full h-10 rounded-md text-xs text-white p-1'>
						Limpar
					</button>
					<button
						onClick={(event) => {
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							if ((lat! === '') || (lng! === '') || ((checkLevel === true) && (levelWater! === '')) || (tableData.length === 0)) {
								notificationHandler(2, 'DADOS INVÁLIDOS. POR FAVOR VERIFIQUE OS DADOS INSERIDOS', notificationDispatch);
								return;
							} else {
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion 
								handleAddSurvey(event, Number(lat!), Number(lng!), checkLevel, Number(levelWater!), checkSPT, tableData);
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion 
								handleResetForm2();
							}
						}}
						type='reset'
						className='bg-blue-helica w-full h-10 rounded-md text-xs text-white p-1'>
						Adicionar sondagem
					</button>
					<button
						type='button'
						className='bg-blue-helica w-full h-10 rounded-md text-xs text-white p-1'
						onClick={handleOpenFileSubmit}
					>
						Submeter
					</button>
				</div>
                <div className='pt-6'>
                    <p className="ml-2 mb-2 text-md sm:text-sm text-blue-helica">Ajuda</p>
                    <ul className='ml-2 px-3 text-xs list-disc space-y-2' >
                        <li>Para começar de novo, e/ou eliminar toda a introdução de dados, clique em `Limpar`;</li>
                        <li>Sempre que terminar a introdução de dados de um ponto de sondagem clique em `Adicionar Sondagem` para inserir novo ponto de sondagem e para guardar a informação inserida;</li>
                        <li>Para terminar a inserção de dados clique em `Submeter`. Lembre-se de `Adicionar Sondagem` antes de submeter os dados;</li>
                    </ul>
                </div>
			</div>
		</>
	);
};

export default Form;