/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react';
import { IconContext } from 'react-icons';
import { HiSearchCircle } from 'react-icons/hi';

interface Props {
	handleSubmit: (lat?: number, lng?: number, value?: string) => void;
}

const LatLong = (props: Props) => {
	const [lat, setLat] = useState<any>();
	const [lng, setLng] = useState<any>();
	const { handleSubmit } = props;

	const submit = (event: { preventDefault: () => void }) => {
		event.preventDefault();
		handleSubmit(Number(lat), Number(lng));
	};

	return (
		<form className='flex flex-wrap' onSubmit={submit}>
			<div>
				<input
					type='text'
					id='inputLat'
					className='w-24 sm:w-32 h-10 text-black border border-r border-solid border-neutral-300 focus:outline-none focus:border-neutral-300 focus:ring-transparent text-center text-xs'
					placeholder="procurar por..."
					value={lat}
					onChange={(e) => setLat(e.target.value)}
				/>
				<input
					type='text'
					id='inputLong'
					className='w-24 sm:w-32 h-10 text-black border border-l-0 border-solid border-neutral-300 focus:outline-none focus:border-neutral-300 focus:ring-transparent text-center text-xs'
					value={lng}
					onChange={(e) => setLng(e.target.value)}
				/>
			</div>
			<div className='flex'>
				<button className='self-center b-0'>
					<IconContext.Provider
						value={{
							color: '#FFFFFF',
							className: 'h-10 w-10',
						}}
					>
						<HiSearchCircle />
					</IconContext.Provider>
				</button>
			</div>
		</form>
	);
};

export default LatLong;