import * as React from 'react';
import button from '../../img/button.png';

const Instruct = () => {
	return (
		<div className="px-3 sm:px-5 sm:flex w-full bg-blue-gray-600">
			<div className="px-1 sm:px-2 sm:w-1/2 space-y-2">
				<p className='font-bold py-6 text-base sm:text-base text-center'>Quero pesquisar sobre solos em território português</p>
				<div className='flex justify-center'>
					<ol className='list-decimal text-xs sm:text-xs text-black space-y-2 w-5/6 sm:w-3/4' >
						<li>Insira a localização na pesquisa acima, em coordenadas (formato do Google Maps), ou por morada/local.</li>
						<li>Verifique os resultados, de acordo com os dados das sondagens mais próximas.</li>
						<li>Selecione pontos com sondagens geotécnicas e veja os resultados de cada região.</li>
					</ol>
				</div>
			</div>
			<div className="px-1 py-6 sm:px-2 sm:py-0 sm:w-1/2 space-y-2">
				<p className='font-bold py-6 text-base sm:text-base text-center'>Quero inserir dados de um relatório geotécnico</p>
				<div className='flex justify-center'>
					<ol className='list-decimal text-xs sm:text-xs text-black space-y-2 w-5/6 sm:w-3/4 mb-10'>
						<li>
                            <div className='float-left'>Clique em </div>
                            <div className='float-left'>
                                <img src={button} className='h-6 ml-2 mr-2'></img>
                            </div>
                            <div> para aceder ao formulário.</div>
                        </li>
						<li>Adicione os resultados de cada sondagem do respectivo estudo com &quot;Adicionar sondagem&quot;.</li>
						<li>Após inserir todas as sondagens/estudos, carregue em &quot;Submeter&quot;.</li>
						<li>Insira o relatório geotécnico referente aos dados introduzidos para validação de resultados. Deixe-nos o seu contacto caso exista a necessidade de esclarecimentos e para receber atualizações sobre o estado da submissão.</li>
						<li>Os dados foram submetidos e, após a revisão, estarão disponíveis na plataforma para visualização.</li>
					</ol>
				</div>
			</div>
		</div>
	);
};
export default Instruct;