import { mapState } from '../../types/ContextTypes';

export const options = (mapContext: mapState) => {
	return {
		mapTypeControlOptions: {
			mapTypeIds: ['styled_map', 'roadmap', 'satellite'],
		},
		zoom: mapContext.zoom,
		center: {
			lat: mapContext.lat,
			lng: mapContext.lng,
		},
		disableDefaultUI: false,
		mapId: process.env.REACT_APP_MAP_ID as string,
	};
};