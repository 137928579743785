import React from 'react';
import SocialsList from './SocialsList';
import setas from '../../img/setas.png';
import prrBanner from '../../img/BARRA_2024.jpg';
import Popup from '../Navbar/Popup';
import Text from '../Navbar/Text';

const Footer = () => {

    const [showPopup, setShowPopup] = React.useState(false);

    const handleOpenPopup = () => {
		setShowPopup(!showPopup);
	};

    const [showTxt, setShowTxt] = React.useState(false);

    const handleOpenTxt = () => {
		setShowTxt(!showTxt);
	};


	return (
        <>
        { showPopup ?
            <>
                <div className='bg-white z-50 h-auto sm:h-2/3 overflow-x-hidden'>
                    <Popup />
                </div>
            </>
            : null}

        { showTxt ?
            <>
                <div className='bg-white z-50 h-auto sm:h-2/3 overflow-x-hidden'>
                    <Text />
                </div>
            </>
            : null}

		<footer className="p-3 sm:p-8 bg-blue-helica border-t border-neutral-300 w-full text-black bottom-0 pattern-dots pattern-gray-600 pattern-bg-blue-helica pattern-opacity-100 pattern-size-2">
			<div className="grid grid-rows-3 grid-cols-1 md:grid-cols-3 md:grid-rows-1 sm:items-center justify-between sm:ml-16">
				<div>
                    <p className="text-sm sm:text-lg text-white font-semibold">Repositório de estudos do solo realizados em Portugal</p>
                </div>
                <div className='flex sm:justify-center md:justify-end space-x-4 mx-auto'>
                    <button type="button" onClick={handleOpenTxt} className='flex items-center space-x-4 pl-4 w-32 h-10 md:w-fit border-blue-helica rounded-sm border bg-white'>
                        <a className="font-semibold font-sans sm:ml-4 text-xs sm:text-xs text-blue-helica">SAIBA MAIS</a>
                        <img src={setas} className='h-2 pr-4'></img>						
                    </button>
                    <button type="button" onClick={handleOpenPopup} className='flex items-center space-x-2 pl-4 w-32 md:w-fit h-10 border-blue-helica rounded-sm border bg-white'>
                        <a className="font-semibold font-sans sm:ml-4 text-xs sm:text-xs text-blue-helica">VAMOS FALAR!</a>
                        <img src={setas} className='h-2 pr-4'></img>						
                    </button>
                </div>
                <div className='w-fit mx-auto pt-2 pb-2'>
					{/*<li className='sm:text-center'>*/}
						{/* <span className='text-sm sm:text-lg text-white font-semibold'>Vamos falar!</span> */}
						<SocialsList />
					{/*</li>*/}
                </div>
				{/*</ul>*/}
			</div>
		</footer>
    </>
	);
};

export default Footer;