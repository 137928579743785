import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { geoReportState, mapDispatch, notificationDispatch, userMarkerDispatch, mapState } from '../types/ContextTypes';
import { GeoReport } from '../types/GeoReportTypes';
import { GeocoderResults } from '../types/MapTypes';
import { notificationHandler, userMarkerHandler } from './ContextHandlers';
import { } from 'react';

export const getRandomInt = (max: number): number => {
	return Math.floor(Math.random() * max);
};


//==================================================================================================================================================================================================================================
//Method that checks if a given coordinate is within Portugal territory
export const isItPortugal = (results: GeocoderResults): boolean => {
	for (let i = 0; i <= results[0].address_components.length - 1; i++) {
		if ((results[0].address_components[i].long_name).toUpperCase() === 'PORTUGAL') {
			return true;
		}
	}
	return false;
};


//==================================================================================================================================================================================================================================
//These two methods loop through every report in the data base and compare it's LatLng to the user's input to check which one is the one closer to the selected coordinate
export const checkClosestPoint = (lat: number, lng: number, reportMap: { [key: string]: GeoReport[] }) => {
	let distance: number | null = null;
	let curDistance = 0;
	let closestLat = 0;
	let closestLng = 0;
	let closestReport: GeoReport | undefined;

	Object.entries(reportMap).map(([, reportArray]) => {
		reportArray.forEach((element) => {
			curDistance = checkDistance(lat, element.lat, lng, element.lng);
			if (!distance || distance > curDistance) {
				distance = curDistance;
				closestLat = element.lat;
				closestLng = element.lng;
				closestReport = element;
			}
		});
	});

	return { distance, closestLat, closestLng, closestReport };
};

const checkDistance = (lat1: number, lat2: number, lng1: number, lng2: number) => {
	const R = 6371e3; // metres
	const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
	const φ2 = lat2 * Math.PI / 180;
	const Δφ = (lat2 - lat1) * Math.PI / 180;
	const Δλ = (lng2 - lng1) * Math.PI / 180;

	const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
		Math.cos(φ1) * Math.cos(φ2) *
		Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

	const distance = R * c; // in metres

	return distance;
};


//==================================================================================================================================================================================================================================
//Animates a given point in the map based on latitude and longite while de-animating any other currently animated points
export const callInfoBox = (
	lat: number,
	lng: number,
	geoReports: { [key: string]: GeoReport[] },
	mapState: mapState,
) => {
	const map = mapState.map;
	const infoWindow = new google.maps.InfoWindow();
	Object.entries(geoReports).map(([, reports]) => {
		reports.forEach((marker: GeoReport) => {
			if (marker.lat === lat && marker.lng === lng) {
				infoWindow.setPosition({ lat, lng });
				infoWindow.setContent(`
			<div className='py-5 flex space-x-5 justify-center items-center'>
				<p className='font-bold text-xs sm:text-sm'>Ensaio mais próximo a X m</p>
				<button type='button' className='bg-blue-helica w-40 rounded text-xs sm:text-sm text-white p-1'>Notificar-me quando estiver mais próximo</button>
			</div>
	    `);
				infoWindow.open({ map });
			}
		});
	});
};

export const findMode = (string: string[]) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const frequency: any = {};
	let maxFrequency = 0;
	let mode = null;

	for (let i = 0; i < string.length; i++) {
		if (frequency[string[i]]) {
			frequency[string[i]]++;
		} else {
			frequency[string[i]] = 1;
		}

		if (frequency[string[i]] > maxFrequency) {
			maxFrequency = frequency[string[i]];
			mode = string[i];
		}
	}

	return mode;
};

export const findAverage = (numbers: number[]) => {
	const sum = numbers.reduce((total, current) => total + current, 0);
	const average = sum / numbers.length;

	return average;
};

const colorMap = async (mapState: mapState, lat: number, lng: number, closestLat: number, closestLng: number, geoReportState: geoReportState, notificationDispatch: notificationDispatch) => {
	const address: string[] = [];
	let closestPlaceId = '';
	Object.entries(geoReportState.map).map(([, reports]) => {
		reports.forEach(report => {
			if (report.lat === closestLat && report.lng === closestLng) {
				closestPlaceId = report.placeId;
				report.surveys.forEach(survey => {
					survey.table.forEach(data => {
						address.push(data.soilType);
					});
				});
			}
		});
	});

	if (address.length < 1) return;
	const mode = findMode(address);
	if (mode === null) return;
	const soilColor = getSoilTypeColor(mode);
	const { placeId } = await formSubmitGeoCoder(Number(lat), Number(lng), notificationDispatch);

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	addZoneLayer(mapState.map!, soilColor, placeId, closestPlaceId);
};


//==================================================================================================================================================================================================================================
//Geocoding and reverse geocoding methods
export const reverseGeocoder = async (lat: number, lng: number, notificationDispatch: notificationDispatch, geoReportState: geoReportState, userMarkerDispatch: userMarkerDispatch, mapDispatch: mapDispatch, mapState: mapState): Promise<{ distance: number | null, closestReport: GeoReport | null }> => {
	if (isNaN(lat) || isNaN(lng)) {
		notificationHandler(2, 'Latitude and Longitude devem ser números', notificationDispatch);
		return { distance: null, closestReport: null };
	}

	const geocoder = new window.google.maps.Geocoder();
	let closestReport: GeoReport | null = null;
	let distance: number | null = null;
	await geocoder.geocode({ location: { lat: lat, lng: lng } }, (results, status) => {
		if (status !== 'OK') {
			notificationHandler(2, 'De momento, apenas disponibilizamos informações dentro de território nacional', notificationDispatch);
		}
		if (!results) return;
		if (!isItPortugal(results)) {
			notificationHandler(
				0,
				'De momento, apenas disponibilizamos informações dentro de território nacional',
				notificationDispatch,
			);
			return { distance: null, closestReport: null };
		}
		const { distance: dist, closestLat, closestLng, closestReport: report } = checkClosestPoint(
			Number(lat),
			Number(lng),
			geoReportState.map,
		);

		if (dist && dist > 200000) {
			notificationHandler(0, 'Infelizmente, de momento, não possuímos qualquer informação relevante para a zona indicada', notificationDispatch);
			return { distance: null, closestReport: null };
		}
		userMarkerHandler(Number(lat), Number(lng), closestLat, closestLng, userMarkerDispatch);
		colorMap(mapState, lat, lng, closestLat, closestLng, geoReportState, notificationDispatch);
		if (report) closestReport = report;
		distance = dist;
	});
	if (closestReport)
		return {
			distance: distance,
			closestReport: closestReport
		};
	else return { distance: null, closestReport: null };
};


export const mapClickGeoCoder = async (lat: number, lng: number, notificationDispatch: notificationDispatch) => {
	let boolean = true;
	const geocoder = new window.google.maps.Geocoder();
	await geocoder.geocode({ location: { lat: lat, lng: lng } }, (results, status) => {
		if (status !== 'OK') boolean = false;
		if (!results) boolean = false;
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		if (!isItPortugal(results!)) {
			boolean = false;
			notificationHandler(
				2,
				'De momento apenas permitimos dados dentro de território nacional',
				notificationDispatch
			);
		};
		return true;
	});
	return boolean;
};

export const geocoder = async (val: string, userMarkerDispatch: userMarkerDispatch, mapDispatch: mapDispatch, notificationDispatch: notificationDispatch, geoReportState: geoReportState, mapState: mapState): Promise<{ distanceBetween: number | null, closestReport: GeoReport | null }> => {
	const results = await getGeocode({ address: val });
	const { lat, lng } = getLatLng(results[0]);
	let report: GeoReport | undefined = undefined;
	let distBetween: number | null = null;
	if (isItPortugal(results)) {
		const { distance, closestLat, closestLng, closestReport } = checkClosestPoint(
			Number(lat),
			Number(lng),
			geoReportState.map,
		);

		if (distance && distance > 50000) {
			notificationHandler(
				0,
				'Infelizmente, de momento, não possuímos qualquer informação relevante para a zona indicada',
				notificationDispatch,
			);
			//return { distanceBetween: null, closestReport: null };
		}
		distBetween = distance;
		userMarkerHandler(Number(lat), Number(lng), closestLat, closestLng, userMarkerDispatch);
		colorMap(mapState, lat, lng, closestLat, closestLng, geoReportState, notificationDispatch);
		report = closestReport;
	} else {
		notificationHandler(0, 'De momento, apenas disponibilizamos informações dentro de território nacional', notificationDispatch);
	}

	if (report) return { distanceBetween: distBetween, closestReport: report };
	else return { distanceBetween: null, closestReport: null };
};

export const formSubmitGeoCoder = async (lat: number, lng: number, notificationDispatch: notificationDispatch) => {
	const geocoder = new window.google.maps.Geocoder();
	const results = await geocoder.geocode({ location: { lat: lat, lng: lng } }, (results, status) => {
		if (status !== 'OK') {
			notificationHandler(2, 'error', notificationDispatch);
		}
		if (!results) return;
		if (!isItPortugal(results)) {
			notificationHandler(
				0,
				'De momento, apenas disponibilizamos informações dentro de território nacional',
				notificationDispatch,
			);
			return;
		}
		return results;
	});
	let adress = '';
	let placeId = '';
	results.results.forEach((element) => {
		element.address_components.forEach((address) => {
			if (getRegion(address.long_name) !== '') adress = getRegion(address.long_name);
			if (element.types[0] === 'administrative_area_level_1') placeId = element.place_id;
		});
	});
	return { adress, placeId };
};

//==================================================================================================================================================================================================================================
//Cicle Madness

export const getRadius = (reports: { [key: string]: GeoReport[] }, marker: GeoReport) => {
	let minimumDistance = 50000;
	Object.entries(reports).map(([, reports]) => {
		reports.forEach((element: GeoReport) => {
			if (marker.lat !== element.lat || marker.lng !== marker.lng) {
				const distance = checkDistance(marker.lat, element.lat, marker.lng, element.lng);
				if (distance < minimumDistance) minimumDistance = distance;
			}
		});
	});
	return minimumDistance / 2;
};

const addZoneLayer = (map: google.maps.Map, color: string, placeId: string, closestPlaceId: string) => {
	if (!map.getMapCapabilities().isDataDrivenStylingAvailable) return;
	const featureLayer = map.getFeatureLayer(google.maps.FeatureType.ADMINISTRATIVE_AREA_LEVEL_1);
	const featureStyleOptions: google.maps.FeatureStyleOptions = {
		strokeColor: '#FA8334',
		strokeOpacity: 0,
		strokeWeight: 0,
		fillColor: color,
		fillOpacity: 0.7,
	};
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	featureLayer.style = (options: { feature: { placeId: string } }) => {
		if (options.feature.placeId === placeId || options.feature.placeId === closestPlaceId) {
			return featureStyleOptions;
		}
	};
};

export const getSoilTypeColor = (soilType: string) => {
	let color: string;
	switch (soilType) {
	case 'Areia argilosa':
		color = '#087E8B';
		break;
	case 'Areia bem-graduada':
		color = '#087E8B';
		break;
	case 'Areia mal-graduada':
		color = '#087E8B';
		break;
	case 'Areia siltosa':
		color = '#087E8B';
		break;
	case 'Argila com plasticidade alta':
		color = '#BFD7EA';
		break;
	case 'Argila com plasticidade baixa':
		color = '#BFD7EA';
		break;
	case 'Argila dura':
		color = '#BFD7EA';
		break;
	case 'Argila inorgânica':
		color = '#BFD7EA';
		break;
	case 'Argila rígida':
		color = '#BFD7EA';
		break;
	case 'Argila siltosa':
		color = '#BFD7EA';
		break;
	case 'Cascalho argiloso':
		color = '#95B2B0';
		break;
	case 'Cascalho bem-graduado':
		color = '#95B2B0';
		break;
	case 'Cascalho mal-graduado':
		color = '#95B2B0';
		break;
	case 'Cascalho siltoso':
		color = '#95B2B0';
		break;
	case 'Silte arenoso':
		color = '#647AA3';
		break;
	case 'Silte inorgânico':
		color = '#647AA3';
		break;
	case 'Silte orgânico':
		color = '#647AA3';
		break;
	case 'Turfa e outros solos altamente orgânicos':
		color = '#DBD053';
		break;
	default: color = '#FA8334'; break;
	}

	return color;
};
//==================================================================================================================================================================================================================================
//Extreme switch case to select and group each district
export const getRegion = (address: string): string => {
	const region = address.toUpperCase();
	switch (region) {
	case 'LEIRIA':
		return 'LEIRIA';
	case 'LISBOA':
		return 'LISBOA';
	case 'BRAGA':
		return 'BRAGA';
	case 'COIMBRA':
		return 'COIMBRA';
	case 'PORTO':
		return 'PORTO';
	case 'AVEIRO':
		return 'AVEIRO';
	case 'GUARDA':
		return 'GUARDA';
	case 'VISEU':
		return 'VISEU';
	case 'CASTELO BRANCO':
		return 'CASTELO BRANCO';
	case 'SANTARÉM':
		return 'SANTARÉM';
	case 'VILA REAL':
		return 'VILA REAL';
	case 'PORTALEGRE':
		return 'PORTALEGRE';
	case 'BEJA':
		return 'BEJA';
	case 'FARO':
		return 'FARO';
	case 'SETÚBAL':
		return 'SETÚBAL';
	case 'VIANA DO CASTELO':
		return 'VIANA DO CASTELO';
	case 'BRAGANÇA':
		return 'BRAGANÇA';
	case 'ÉVORA':
		return 'ÉVORA';
	case 'LEIRIA DISTRICT':
		return 'LEIRIA';
	case 'COIMBRA DISTRICT':
		return 'COIMBRA';
	case 'LISBOA DISTRICT':
		return 'LISBOA';
	case 'BRAGA DISTRICT':
		return 'BRAGA';
	case 'PORTO DISTRICT':
		return 'PORTO';
	case 'AVEIRO DISTRICT':
		return 'AVEIRO';
	case 'GUARDA DISTRICT':
		return 'GUARDA';
	case 'VISEU DISTRICT':
		return 'VISEU';
	case 'CASTELO BRANCO DISTRICT':
		return 'CASTELO BRANCO';
	case 'SANTARÉM DISTRICT':
		return 'SANTARÉM';
	case 'VILA REAL DISTRICT':
		return 'VILA REAL';
	case 'PORTALEGRE DISTRICT':
		return 'PORTALEGRE';
	case 'BEJA DISTRICT':
		return 'BEJA';
	case 'FARO DISTRICT':
		return 'FARO';
	case 'SETÚBAL DISTRICT':
		return 'SETÚBAL';
	case 'VIANA DO CASTELO DISTRICT':
		return 'VIANA DO CASTELO';
	case 'BRAGANÇA DISTRICT':
		return 'BRAGANÇA';
	case 'ÉVORA DISTRICT':
		return 'ÉVORA';
    case 'MADEIRA':
		return 'MADEIRA';
    case 'AÇORES':
		return 'AÇORES';
	default:
		return '';
	}
};