import React, { useEffect } from 'react';
import { useNotificationDispatch, useNotificationState } from '../../context/NotificationContext';
import setasWhite from '../../img/setas-white.png';

const Notification = () => {
	const state = useNotificationState();

	let color: string;
	switch (state.type) {
	case 1:
		color = 'bg-black';
		break;
	case 2:
		color = 'bg-black';
		break;
	default:
		color = 'bg-black';
		break;
	}

	const visibility = (state.isVisible && 'top-16') || '-top-full';

	const time = 7500;

	return <Index color={color} message={state.message} visibility={visibility} time={time} />;
};

interface Props {
	color: string;
	message: string;
	visibility: string;
	time: number;
}

const Index = (props: Props) => {
	const { color, message, visibility, time } = props;
	const dispatch = useNotificationDispatch();

	useEffect(() => {
		setTimeout(() => {
			dispatch.setIsVisible(false);
		}, time);
	});

	return (
		<div
			className={`fixed transition ease-in-out delay-550 ${visibility} self-center flex justify-between items-center p-3 rounded-sm border-4 border-white ${color}`}
		>
			<div className='w-auto items-center m-2 flex'>
				<img src={setasWhite} className='h-2 m-2'></img>						
				<p className="text-white text-xs">{message}</p>
				<img src={setasWhite} className='h-2 m-2 rotate-180'></img>						
			</div>
		</div>
	);
};

export default Notification;