import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import App from './App';
import { NotificationProvider } from './context/NotificationContext';
import { MapProvider } from './context/MapContext';
import { UserMarkerProvider } from './context/UserMarkerContext';
import { GeoReportProvider } from './context/GeoReportContext';

//const root = ReactDOM.createRoot(
 
//);
ReactDOM.render(
	<NotificationProvider>
		<MapProvider>
			<GeoReportProvider>
				<UserMarkerProvider>
					<App />
				</UserMarkerProvider>
			</GeoReportProvider>
		</MapProvider>
	</NotificationProvider>,
    document.getElementById('root') as HTMLElement
);
