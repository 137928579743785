import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import setas from '../../img/setas-white.png';



export default function Text(){
    const [showTxt, setTxt] = React.useState(true);
    
  return(
    <>
    {showTxt ?
    <>
        <div className='w-full bg-red'>
            <p className='mt-8 text-center text-xl font-bold text-blue-helica align-middle'>SAIBA O PORQUÊ DO DADOS DO SOLO</p>
            <div className="grid grid-rows-3 grid-cols-1 md:grid-cols-3 md:grid-rows-1 h-fit content-center">
                <div className='p-8 leading-loose tracking-wide'>
                    <p>No panorama da construção civil em Portugal, a realização de estudos geotécnicos revela-se como uma etapa crucial para garantir a segurança e a sustentabilidade das infraestruturas.<br/>A Helica pretende, com este site, destacar a relevância de manter um registo aberto sobre os tipos de solo em Portugal, permitindo o acesso gratuito à informação vital para projetos de construção.</p>
                </div>
                <div className='p-8 leading-loose tracking-wide'>
                    <p>Manter um registo aberto dos estudos geotécnicos realizados em Portugal é uma iniciativa crucial para promover a partilha de conhecimento. Ao disponibilizar informações sobre os tipos de solo, empresas e profissionais do setor podem aceder a dados valiosos sem custos adicionais, promovendo a eficiência e a inovação na indústria da construção.</p>
                </div>
                <div className='p-8 leading-loose tracking-wide'>
                    <p>Acreditamos agora que profissionais têm acesso a dados fiáveis para fundamentar as suas decisões de projeto. Dados estes que provêem de ensaios SPT e DPSH, e cuja disponibilização do site é feita com a validação da equipa de engenharia da Helica. Ao facilitar o acesso à informação, a indústria pode evoluir de maneira eficiente, segura e inovadora, garantindo um futuro robusto para as infraestruturas do país.</p>
                </div>
            </div>
            <div className='mb-4 flex justify-center'>
                <button type='button' className='flex justify-center text-xs underline text-light' onClick={() => setTxt(false)}>fechar</button>
            </div>
        </div>
        </>
        : null}
    </>
);
};
