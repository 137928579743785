import React, { createContext, useContext, useState } from 'react';
import { SurveyState, SurveyDispatch } from '../types/ContextTypes';

const initialState: SurveyState = {
	length: 0,
};

const SurveyContext = createContext<SurveyState | undefined>(undefined);
const SurveyDispatchContext = createContext<SurveyDispatch | undefined>(undefined);

export const useSurveyState = () => {
	const state = useContext(SurveyContext);
	if (!state) {
		throw new Error('SurveyContext must be used within a provider.');
	}
	return state;
};

export const useSurveyDispatch = () => {
	const dispatch = useContext(SurveyDispatchContext);
	if (!dispatch) {
		throw new Error('SurveyDispatchContext must be used within a provider.');
	}
	return dispatch;
};

export const SurveyProvider = ({ children }: { children: React.ReactNode }) => {
	const [state, setState] = useState(initialState);
	const dispatch = {
		setLength: (length: number) => setState((prevState) => ({...prevState, length})),
	};

	return (
		<SurveyContext.Provider value={state}>
			<SurveyDispatchContext.Provider value={dispatch}>
				{children}
			</SurveyDispatchContext.Provider>
		</SurveyContext.Provider>
	);
};