import React, { createContext, useContext, useState, useEffect } from 'react';
import { geoReportDispatch, geoReportState } from '../types/ContextTypes';
import { GeoReport } from '../types/GeoReportTypes';

const initialState: geoReportState = {
	map: {},
	length: 0,
	styles: [],
};

const GeoReportContext = createContext<geoReportState | undefined>(undefined);
const GeoReportDispatchContext = createContext<geoReportDispatch | undefined>(undefined);

export const useGeoReportState = () => {
	const state = useContext(GeoReportContext);
	if (!state) {
		throw new Error('GeoReportContext must be used within a provider.');
	}
	return state;
};

export const useGeoReportDispatch = () => {
	const dispatch = useContext(GeoReportDispatchContext);
	if (!dispatch) {
		throw new Error('GeoReportDispatchContext must be used within a provider.');
	}
	return dispatch;
};

export const GeoReportProvider = ({ children }: { children: React.ReactNode }) => {
	const [state, setState] = useState(initialState);
	const dispatch = {
		setMap: (map: { [key: string]: GeoReport[] }) => setState((prevState) => ({ ...prevState, map })),
		setLength: (length: number) => setState((prevState) => ({...prevState, length})),
		setStyles: (styles: string[]) => setState((prevState) => ({...prevState, styles})),
	};

	const [markers, setMarkers] = useState<Array<GeoReport>>([]);
    const copyOfMarkers = markers;
	const fetchReports = async () => {
		const response = await fetch(process.env.REACT_APP_BACKEND + '/api/report', { method: 'GET', cache: 'no-cache' })
            .then((response) => response.json());
            response.forEach((report: GeoReport) => {
			copyOfMarkers.push({
				id: report.id,
				lat: report.lat,
				lng: report.lng,
				adress: report.adress,
				placeId: report.placeId,
				surveys: report.surveys,
				fileName: report.fileName,
				selectedImage: report.selectedImage
			});
		});
    
		//const json = await response.json();
        //const copyOfMarkers = markers;
		//json.forEach((report: GeoReport) => {
		//	copyOfMarkers.push({
		//		id: report.id,
		//		lat: report.lat,
		//		lng: report.lng,
		//		adress: report.adress,
		//		placeId: report.placeId,
		//		surveys: report.surveys,
		//		fileName: report.fileName,
		//		imgName: report.imgName
		//	});
		//});

		setMarkers([...copyOfMarkers]);

        //const newMarkers = json.map((report: GeoReport) => ({
        //    id: report.id,
        //    lat: report.lat,
        //    lng: report.lng,
        //    adress: report.adress,
        //    placeId: report.placeId,
        //    surveys: report.surveys,
        //    fileName: report.fileName,
        //    imgName: report.imgName
        //})
        //);
        //console.log(newMarkers);

        //setMarkers(newMarkers);
        //console.log('2', markers);

		await dispatch.setMap(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			markers.reduce((acc: any, curr: any) => {
				(acc[curr.adress] = acc[curr.adress] || []).push(curr);
				return acc;
			}, {} as { [key: string]: GeoReport[] }),
		);
        
        //console.log('markers', markers);
	};

	useEffect(() => {
		 fetchReports();
	}, []);		
  
	return (
		<GeoReportContext.Provider value={state}>
			<GeoReportDispatchContext.Provider value={dispatch}>
				{children}
			</GeoReportDispatchContext.Provider>
		</GeoReportContext.Provider>
	);
};
