import * as React from 'react';
import thankYou from '../../img/thank_you.svg';

const ThankYou = () => {
	return (
		<div className="m-5 flex flex-wrap ml-5 space-y-4">
			<div className='mb-1.5 sm:mb-3 space-y-4'>
				<h1 className='text-base sm:text-xl font-bold text-black text-left'>
					Submeter estudo geotécnico
				</h1>
			</div>

			<div className='my-4'>
				<h1 className='text-base sm:text-xl font-semibold text-center'>Obrigado</h1>
				<div className='flex justify-center p-2'>
					<img src={thankYou} className='m-10 w-1/6 h-1/6' />
				</div>
			</div>

			<div>
				<p className='text-sm sm:text-base'>A sua submissão foi realizada com sucesso! Em breve os resultados da sondagem estarão disponíveis para visualização na plataforma</p>
			</div>
		</div>
	);
};
export default ThankYou;