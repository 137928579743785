import React from 'react';
import { IconContext } from 'react-icons';
import {/* AiFillMail,*/ AiFillFacebook, AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';

const SocialsList = () => {
	return (
		<ul className='flex flex-wrap mb-auto justify-center space-x-2 mt-2 sm:mt-0'>
			{/* <li>
				<a href='mailto:info@helica.pt'>
					<IconContext.Provider
						value={{
							color: '#060E9F',
							className: 'h-5 sm:h-8 w-5 sm:w-8',
						}}
					>
						<AiFillMail />
					</IconContext.Provider>
				</a>
			</li> */}
			<li>
				<a href='https://www.facebook.com/helica.portugal'>
					<IconContext.Provider
						value={{
							color: '#FFFFFF',
							className: 'h-8 sm:h-12 w-8 sm:w-12 border sm:p-2 p-1',
						}}
					>
						<AiFillFacebook />
					</IconContext.Provider>
				</a>
			</li>
			<li>
				<a href='https://www.instagram.com/helica.portugal/'>
					<IconContext.Provider
						value={{
							color: '#FFFFFF',
							className: 'h-8 sm:h-12 w-8 sm:w-12 border sm:p-2 p-1',
						}}
					>
						<AiFillInstagram />
					</IconContext.Provider>
				</a>
			</li>
			<li>
				<a href='https://pt.linkedin.com/company/helicaprofile'>
					<IconContext.Provider
						value={{
							color: '#FFFFFF',
							className: 'h-8 sm:h-12 w-8 sm:w-12 border sm:p-2 p-1',
						}}
					>
						<AiFillLinkedin />
					</IconContext.Provider>
				</a>
			</li>
		</ul>
	);
};

export default SocialsList;