import React from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from '@reach/combobox';
import '@reach/combobox/styles.css';
import { IconContext } from 'react-icons';
import { HiSearchCircle } from 'react-icons/hi';

interface Props {
	handleSubmit: (lat?: number, lng?: number, value?: string) => void;
}

const Places = (props: Props) => {
	const { ready, value, setValue, suggestions: { status, data }, clearSuggestions } = usePlacesAutocomplete();
	const { handleSubmit } = props;

	const handleSelect = async (val: string) => {
		setValue(val, false);
		clearSuggestions();
		handleSubmit(undefined, undefined, val);
	};

	const submit = (event: { preventDefault: () => void }) => {
		event.preventDefault();
		handleSubmit(undefined, undefined);
	};

	return (
		<form className='flex flex-wrap' onSubmit={submit}>
			<Combobox onSelect={handleSelect}>
				<ComboboxInput
					value={value}
					onChange={(e) => setValue(e.target.value)}
					placeholder="procurar por..."
					disabled={!ready}
					className='w-42 md:w-64 h-10 text-black border border-r border-solid border-neutral-300 focus:outline-none focus:border-neutral-300 focus:ring-transparent text-center text-xs'
				/>
				<ComboboxPopover>
					<ComboboxList>
						{status === 'OK' &&
							data.map(({ place_id, description }) => (
								<ComboboxOption key={place_id} value={description} />
							))}
					</ComboboxList>
				</ComboboxPopover>
			</Combobox>
			<div className='flex'>
				<button className='self-center b-0'>
					<IconContext.Provider
						value={{
							color: '#FFFFFF',
							className: 'h-10 w-10',
						}}
					>
						<HiSearchCircle />
					</IconContext.Provider>
				</button>
			</div>
		</form>
	);
};

export default Places;